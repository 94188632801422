.basic_info_upcoming {
  padding: 100px;
}

.basic_info_upcoming h2 {
  text-align: left;

  font-size: 4em;
  margin-bottom: 10px;
  color: #fff;
  }
.basic_info_upcoming h3 {
  text-align: left;
  font-size: 2em;
  margin-bottom: 10px;
  color: #fff;
  }
  
.basic_info_upcoming p {
  font-size: 23px;
  color: #fff;
}


.upcoming_link {
  text-decoration: none;

}

.img-container{
text-align: center;
}
  
.image{
height: 700px;

}
  
/* mobile */
@media only screen and (max-width: 800px) {
  .basic_info_upcoming {
    padding: 17px;

    
    }
    
  .image{
    height: 450px;
    box-shadow: 10px ;
    }

  .basic_info_upcoming h2 {
    font-size: 2em;
    color: #fff;
    text-align: left;

  }
  .basic_info_upcoming h3 {
    text-align: left;
    font-size: 1.4em;
    margin-bottom: 10px;
    color: #fff;
    }
  
  .basic_info_upcoming p {
    font-size: 15px;
    color: #fff;
    margin-bottom: 0px;
    text-align: left;
  }

}