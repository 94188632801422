@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900&display=swap');
body {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    color: rgba(122, 12, 12, 0.87);
  }
  
  .title {
    color: rgb(241, 234, 234);
    font-size: 4em;
    text-align: center;
    font-family: 'Poppins', sans-serif;
  }
  
  h3 {
    padding-top: 0.25em;
  }
  
  .vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
      0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 2em 3em !important;
  }

  .vertical-timeline-element-image {
    height: 100px;
    left: 100px;
  }
  .vertical-timeline-element-date {
    color: rgb(27, 172, 140);
  }
  .date {
    color: rgb(255, 255, 255);
  }
  
  #description {
    margin: 1.5em 0 2em 0;
  }
  
  .button {
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    color: white;
  }
  
  .theaterButton {
    background-color: #c92465;
  }
  
  .theaterButton:hover {
    background-color: #e7327b;
  }
  .icon  a::after {
    color: aliceblue !important;
  }
  
  @media only screen and (max-width: 1700px) {
    .vertical-timeline-element-date {
      display: block !important;
      float: none !important;
      margin-top: 1.5em;
    }
  }

    /* mobile */
    @media only screen and (max-width: 800px) {
    
      .title {
        color: rgb(241, 234, 234);
        font-size: 3em;
        text-align: center;
        font-family: 'Poppins', sans-serif;
      }
    }