.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fd484852;
  padding: 1rem;
}

.footer__logo {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.footer__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 5px;
}

.footer__links a {
  color: #fae2e2;
  font-size: 1.5rem;
  display: inline-flex;
  align-items: center;
}

.footer__links a:hover {
  color: #fc393998;
}

.footer__copyright {
  color: #fae2e2;
  margin: 5px;
  font-size: 1.2rem;
  text-align: center;
}


@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
  }
  
  .footer__links {
    margin-top: 1rem;
  }
  
  .footer__links a {
    font-size: 1.2rem;
  }
  
  .footer__logo,
  .footer__copyright {
    margin-bottom: 1rem;
  }
}
