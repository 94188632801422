@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html, 
body {
    height: 100%;
    background-color: #b30c28;
    text-decoration-color: #fff;
    cursor: none;
}
body::-webkit-scrollbar{
  display: none;
}
.background-container::-webkit-scrollbar {
  display: none;
}

a:hover{
  cursor: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.background-container {
  position: relative;
  
  overflow-y: auto;
  
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}


.confetti {
  text-align: center;
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stage-container{
  text-align: center;
  background-image: url("../public/stage.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

}

.basic_info{
  position: relative;
  padding: 100px;
  background: #b30c28;
}

section {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

section::after {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  height: 90px;
  background: linear-gradient(to bottom,#b30c28,transparent);
}

section::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90px;
  background: linear-gradient(to top,#b30c28,transparent);
}

header::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90px;
  background: linear-gradient(to top,#b30c28,transparent);
}

.basic_info h2 {
  font-size: 3.5em;
  margin-bottom: 10px;
  color: #fff;
}
.basic_info h3 {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #fff;
}

.basic_info p {
  font-size: 20px;
  color: #fff;
}

/* .gdg_logo:hover {
  cursor: pointer;
} */


/* Grow */
.hvr-grow {
  height: 30vmin;
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.1);
}

/* Bounce In */
.hvr-bounce-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-in:hover, .hvr-bounce-in:focus, .hvr-bounce-in:active {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}


.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

    /* mobile */
    @media only screen and (max-width: 800px) {
    
      .basic_info h2 {
        font-size: 1.5em;
        margin-bottom: 10px;
        color: #fff;
      }
      
      .basic_info p {
        font-size: 0.1em;
        color: #fff;
      }
      .hvr-grow {
        height: 50vmin;

      }
    }