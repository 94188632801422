.basic_info h2 {
    font-size: 3.5em;
    margin-bottom: 10px;
    color: #fff;
  }
  
  .basic_info p {
    font-size: 1.1em;
    color: #fff;
  }

  
    /* mobile */
    @media only screen and (max-width: 800px) {
    
      .basic_info h2 {
        font-size: 1.5em;
        margin-bottom: 10px;
        color: #fff;
      }
      
      .basic_info p {
        font-size: 0.1em;
        color: #fff;
      }
    
    }