@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900&display=swap');
.navbar {
    display: flex;
    justify-content: center;
    height: 85px;
    position:sticky;
    /* background: linear-gradient(0deg, rgba(179,12,40,1) 13%, rgba(111,5,40,1) 64%, rgba(66,15,39,1) 100%); */
    /* background: linear-gradient(0deg, rgba(179,12,40,1) 13%, rgba(152,5,67,1) 64%, rgba(111,5,40,1) 100%); */
    /* background: linear-gradient(0deg, rgba(179,12,40,1) 2%, rgba(201,36,101,1) 61%, rgba(214,39,125,1) 86%, rgba(227,42,192,1) 100%); */
    background: linear-gradient(0deg, rgba(179,12,40,1) 3%, rgba(201,36,101,1) 79%, rgba(214,39,125,1) 94%);
    font-family: 'Poppins', sans-serif;
  }

.navbar::after{
    content: '';
    background: linear-gradient(top,#b30c28,transparent);
    width: 10px;
}
  
  ul {
    list-style-type: none;
    display: flex;
    justify-content: center;

  }
  
  .list {
    padding-top: 0px;
    text-align: center;
  }

 .link {
    
    color: rgb(243, 224, 243);
    text-decoration: none;
    font-size: 18px;
    font-weight: 550;
    border-radius: 20px;
    padding: 10px 20px;
    margin: 25px;
    background-color: #c04b93;
    transition: background-color 0.3s ease;
  }
  
  .link:hover {
    background-color: #8A073D;
  }


/* Bounce In */
.hvr-bounce-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-in:hover, .hvr-bounce-in:focus, .hvr-bounce-in:active {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

    /* mobile */
    @media only screen and (max-width: 800px) {
    
      .navbar {
        display: flex;
        justify-content: center;
        height: 85px;
        position:sticky;
        /* background: linear-gradient(0deg, rgba(179,12,40,1) 13%, rgba(111,5,40,1) 64%, rgba(66,15,39,1) 100%); */
        /* background: linear-gradient(0deg, rgba(179,12,40,1) 13%, rgba(152,5,67,1) 64%, rgba(111,5,40,1) 100%); */
        /* background: linear-gradient(0deg, rgba(179,12,40,1) 2%, rgba(201,36,101,1) 61%, rgba(214,39,125,1) 86%, rgba(227,42,192,1) 100%); */
        background: linear-gradient(0deg, rgba(179,12,40,1) 3%, rgba(201,36,101,1) 79%, rgba(214,39,125,1) 94%);
        font-family: 'Poppins', sans-serif;
      }
    
      .link {
        
        color: rgb(243, 224, 243);
        text-decoration: none;
        font-size: 15px;
        font-weight: 5000;
        border-radius: 20px;
        padding: 5px 8px;
        margin: 10px;
        margin-top: 20px;
        background-color: #c04b93;
        transition: background-color 0.3s ease;
      }
      

    }