.mouse-capture-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10; /* High enough to be above other content but below spotlights */
    pointer-events: all; /* Clicks pass through */
}

.spotlight {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: fixed;
    background: radial-gradient(circle, rgba(255, 244, 214, 0.781) 0%, transparent 70%);
    transform: translate(-50%, -50%);
    mix-blend-mode: lighten;
    pointer-events: none; /* Allow clicks through */
    z-index: 11; /* Above the mouse-capture-layer */
}